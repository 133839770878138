@use '../../scss/variables' as *;

.event-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 15;
  pointer-events: none;
  transition: .4s;

  @media(min-width: $tablet) {
    align-items: flex-start;
    justify-content: flex-end;
  }

  @media(min-width: $laptop) {
    position: static;
    opacity: 1;
    pointer-events: initial;
    z-index: 1;
  }

  .overlay {
    @media(min-width: $laptop) {
      display: none;
    }
  }

  &__container {
    position: relative;
    height: 50%;
    width: 100%;
    padding: 20px 12px 65px;
    background-color: $white;
    border-radius: 10px 10px 0 0;  
    transform: translateY(100%);
    z-index: 20;
    transition: .4s;

    @media(min-width: $tablet) {
      width: 40%;
      height: 100%;
      padding: 20px 12px;
      border-radius: 10px 0 0 10px;  
      transform: translateY(0);
      transform: translateX(100%);
    }

    @media(min-width: $laptop) {
      width: 100%;
      height: calc(100% - 6px);
      border-radius: 10px;
      transform: translateY(0);
      box-shadow: 0 0 15px 0 rgba($black, .1);
      z-index: 1;
    }
  }

  &__head {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 24px;

    @media(min-width: $laptop) {
      margin-bottom: 12px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    color: $violet;
    text-align: center;
    width: 100%;

    @media(min-width: $laptop) {
      font-size: 14px;
    }
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @media(min-width: $laptop) {
      display: none;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    height: 100%;

    @media(min-width: $laptop) {
      max-height: 335px;
    }
  }

  &__plug {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: $dark-gray;
    text-align: center;
    margin: 10% 0 0 0;

    @media(min-width: $laptop) {
      margin: 0;
    }
  }

  &.open {
    pointer-events: initial;

    .event-modal {
      &__container {
        transform: translateY(0);
      }
    }

    .overlay {
      opacity: 1;
      pointer-events: initial;

      
    }
  }
}