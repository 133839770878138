.move {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);

    &.move-left  {
        left: 0;
        transform: translateX(0);
    }

    &.move-right {
        right: 0;
        left: initial;
        transform: translateX(0);
    }
}