@use '../../../../scss/variables' as *;

.card-program {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 25px 15px;
    border-radius: 5px;
    border: 1px solid $gray-1;
    background-color: $white;
    box-shadow: 0px 0px 10px 0px rgba($black, 0.03);

    &-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        &__title {
            font-size: 16px;
            font-weight: 400;
            line-height: 100%;
            color: rgba(#1E1D1D, .4);
        }

        &__desc {
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 100%;
            color: $dark-gray;
        }
    }
}