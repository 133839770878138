@use '../../../scss/variables' as *;

.schedule-event {
  display: grid;
  align-items: center;
  grid-template-columns: 3px 18px 1fr 24px auto;
  gap: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.2;
  color: $dark-gray;

  @media(min-width: $tablet) {
    grid-template-columns: 1.5px 12px 1fr auto;
    gap: 4px;
    font-size: 9px;
  }

  &::before {
    content: '';
    width: 3px;
    height: 16px;
    border-radius: 9px;
    background-color: $gray-line;

    @media(min-width: $tablet) {
      width: 1.5px;
      border-radius: 0;
    }
  }

  &.red-event {
    &::before {
      background-color: $red-line;
    }
  }

  &.yellow-event {
    &::before {
      background-color: $yellow-line;
    }
  }

  &.green-event {
    &::before {
      background-color: $green;
    }
  }

  &__flag {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
    object-fit: contain;

    @media(min-width: $tablet) {
      width: 12px;
      height: 12px;
      flex: 0 0 12px;
    }
  }

  &__name {
    text-align: left;

    @media(min-width: $tablet) {
      display: none;
    }
  }

  &__btn {
    margin-right: 4px;

    @media(min-width: $tablet) {
      width: 12px;
      height: 12px;
      flex: 0 0 12px;
      border-radius: 1px;
      background-color: rgba($gray-2, .7);
      margin-left: auto;
    }

    .icon {
      @media(min-width: $tablet) {
        width: 10px;
        height: 10px;
        flex: 0 0 10px;
      }
    }
  }

  &__time {
    @media(min-width: $tablet) {
      grid-column: 5 / 6;
    }
  }
}