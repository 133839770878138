.radiobtns {
    &__title {
        font-weight: 700;
        font-size: 16px;
        margin: 0;
    }

    &__groups {
        margin-top: 16px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        &--col {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}