@use '../../scss/variables' as *;

.attempts-answer {
  display: grid;
  grid-template-columns: 1fr 20px;
  padding: 12px;
  border-radius: 5px;
  border: 1.5px solid rgba(#1E1D1D, .4);
  box-shadow: 0 0 15px 0 rgba($black, .1);

  @media(min-width: $tablet) {
    grid-template-columns: 34px repeat(3, 1fr) 20px;
    padding: 12px 10px 12px 6px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: $dark-1;
    opacity: .6;
    margin-bottom: 12px;

    @media(min-width: $tablet) {
      display: none;
    }
  }

  &__count {
    display: none;

    @media(min-width: $tablet) {
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      color: $dark-1;
      opacity: .6;
    }
  }

  &__question {
    @media(min-width: $tablet) {
      grid-column: 5 / 6;
      grid-row: 1 / 2;
      margin: auto 0;
    }

    .icon {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
    }
  }

  &-block {
    grid-column: 1 / 3;
    margin-bottom: 12px;

    @media(min-width: $tablet) {
      grid-column: span 1;
      margin-bottom: 0;
      margin-right: 24px;
    }

    &--definition {
      align-items: flex-start;

      @media(min-width: $tablet) {
        grid-row: span 5;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__answers {
    display: grid;
    gap: 0 12px;
    grid-column: span 2;
    margin-bottom: 12px;

    @media(min-width: $tablet) {
      gap: 0px;
      grid-column: 3 / 5;
      grid-template-columns: repeat(2, 1fr);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--2 {
      grid-template-columns: repeat(2, 1fr);

      .attempts-answer-block {
        grid-column: span 1;
      }
    }
  }

  &.correct {
    border-color: $green;
  }

  &.incorrect {
    border-color: $red-secondary;
  }
}