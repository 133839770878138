.toggle-btns {
  position: relative;
  width: 100%;
  height: 14px;
  cursor: pointer;

  .link {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: max-content;

    @media(min-width: $tablet) {
      font-size: 16px !important;
    }
  }

  &.toggle {
    .toggle-btns {
      &__show {
        opacity: 0;
        pointer-events: none;
      }

      &__hide {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  &__show {
    opacity: 1;
    pointer-events: initial;
  }

  &__hide {
    opacity: 0;
    pointer-events: none;
  }
}