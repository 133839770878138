@use '../../scss/variables' as *;

.textarea {
  position: relative;

  &__wrapper {
    height: 165px;
  }

  textarea {
    height: 100%;
    border: 0;
    resize: none;
    outline: none;
  }

	&__counter {
		position: absolute;
    bottom: 10px;
    right: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $dark-gray-1;
	}
}