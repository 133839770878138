.profile {
  margin-top: 25px;
  height: 100%;

  &__content {
    margin-top: 35px;
  }

  &-block {
    max-width: 765px;
    margin: 0 auto;
  }

  &-form {
    max-width: 550px;
    margin: 0 auto;
  }

  &__btns {
    margin-top: 35px;
  }
}