@use '../../../../scss/variables' as *;

.modal-finances-card {
  padding: 15px;
  font-weight: 400;
  background-color: $white;
  border: 1px solid $gray-1;
  box-shadow: 0px 0px 10px rgba($black, 0.02);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__date {
    font-size: 12px;
    line-height: 100%;
    color: $dark-gray;
  }

  &__sum {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
  }

  &-text {
    font-size: 14px;
    line-height: 100%;
    color: rgba($black, .4);
  }
}