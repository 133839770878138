@use '../../scss/variables' as *;

.tooltip {
  position: relative;

  &__content {
    position: relative;
    overflow: hidden;
  }

  &__text {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    background-color: $white;
    color: $black;
    border-radius: 10px;
    padding: 8px 10px;
    box-shadow: 0 0 4px 4px rgba($black, .1);
    white-space: nowrap;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: .4s;

    &::before {
      content: "";
      position: absolute;
      top: calc(100% + 1px);
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: $white transparent transparent transparent;
    }

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: $white transparent transparent transparent;
    }
  }

  &--hover {
    @media(hover: hover) {
      &:hover {
        .tooltip__text {
          opacity: 1; 
          pointer-events: initial;
        }
      }
    }
  }

  &.show {
    .tooltip__text {
      opacity: 1; 
      pointer-events: initial;
    }
  }
}
