.tabs {
	display: flex;
	align-items: center;
	gap: 10px;

	li {
		display: flex;
		flex: 1;

		a,
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 600;
			font-size: 16px;
			line-height: 140%;
			flex: 1;
			padding: 8px;
			border-radius: 10px;
			background-color: $gray-20;
			color: $dark-gray-2;
			transition: .3s;

			&:hover:not(.active) {
				background-color: rgba($dark-gray-2, .2);
			}

			&.active {
				background-color: rgba($green, .9);
				color: $white;
			}
		}
	}
}