@use '../../scss/variables' as *;

.head {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  @media(min-width: $tablet) {
    gap: 6px;
  }
    
  &__back {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    left: -5px;
    background-color: transparent;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    z-index: 1;
    transition: .4s;

    @media(min-width: $tablet) {
      display: flex;
      align-items: center;
      padding: 5px 10px 5px 5px;
    }

    @media(hover: hover) {
      &:hover {
        background-color: $gray-border;
      }
    }


    span {
      display: none;
      
      @media(min-width: $tablet) {
        display: flex;
        font-size: 16px;
        line-height: 1;
        color: $dark;
      }
    }
  }
    
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    color: $violet;
    margin: 0;
    width: 100%;

    @media(min-width: $tablet) {
      font-size: 26px;
      line-height: 1.3;
    }
  }

  &__subtitle {
    font-size: 14px;
    line-height: 1.3;
    color: $violet;

    @media(min-width: $tablet) {
      font-size: 18px;
      line-height: 1;
    }
  }

  &__tooltip {
    position: absolute;
    right: 0;
    top: 5px;

    .icon {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
    }
  }
} 