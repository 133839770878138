.logo {
  width: 44px;
  height: 50px;
  flex: 0 0 44px;
  display: flex;

  img {
    width: 100%;
    height: 100%;
  }
}