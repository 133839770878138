.header-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-bottom: 1px solid rgba($gray-border, .3);

  img {
    width: 44px;
    height: 50px;
    flex: 0 0 44px;
  }
}