.page {
  margin-top: 25px;

  &-blocks {
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media(min-width: $tablet) {
      gap: 35px;
    }
  }
  
  &-block {
    display: flex;
    flex-direction: column;

    &.open {
      .page {
        &-block {
          &-head {
            svg {
              transform: rotate(-270deg);
            }
          }
        }
      }
    }

    &-head {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      &__title {
        color: $green;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
      }

      svg {
        color: $green;
        transform: rotate(-90deg);
      }
    }

    &__content {
      height: 0;
      overflow: hidden;
      transition: .4s;

      .page__cards,
      .page__table {
        margin-top: 10px;
      }
    }
  }  

  &__cards {
    @media(min-width: $tablet) {
      display: none;
    }
  }

  &__table {
    display: none;
    max-width: 1280px;

    @media(min-width: $tablet) {
      display: flex;
    }

    &-wrapper {
      width: 100%;

      table {
        &>tbody {
          &>tr {
            &:nth-child(2n + 1) {
              background-color: $gray-1;
            }
          }
        }
      }
    }
  }
}