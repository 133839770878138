@use 'variables' as *;
@use 'mixins' as *;

// base
@import './base/base';
@import './base/fonts';

// parts
@import './parts/link.scss';
@import './parts/logo.scss';
@import './parts/exit.scss';
@import './parts/plug.scss';
@import './parts/form.scss';
@import './parts/radiobtns.scss';
@import './parts/tabs.scss';
@import './parts/card-pay';
@import './parts/course-name';
@import './parts/select';
@import './parts/events-color';
@import './parts/toggle-btns';
@import './parts/tags';
@import './parts/courses-block';
@import './parts/check-icon';
@import './parts/move';
@import './parts/shake';
@import './parts/btn-download';
@import './parts/label';
@import './parts/icon';
@import './parts/counter';
@import './parts/success-field';

// components
@import './components/header.scss';
@import './components/header-auth.scss';
@import './components/footer.scss';
@import './components/schedule.scss';
@import './components/finances';
@import './components/modal';
@import './components/table';
@import './components/finances-pay';
@import './components/finance-message';
@import './components/profile';
@import './components/modal-info';
@import './components/general';
@import './components/contacts';
@import './components/reviews';
@import './components/settings';
@import './components/page';
@import './components/program';
@import './components/homework';
@import './components/attempts';
@import './components/test';

.offscreen {
  position: absolute;
  left: -9999px;
}

.font-blue {
  color: #5d81c4;
}

.drag-n-drop {
  background: #e1f1f8;
  padding: 20px;
  border: 2px dashed #7aafc5;
  border-radius: 5px;

  .row {
    justify-content: center;

    .col-icon {
      margin-right: 20px;
    }
  }
}

.drag-n-drop-files {
  margin-top: 8px;
}

.delete-uploaded-file {
  padding: 8px;
  font-size: 13px;
  background: #c6e7f8;

  img {
    margin-left: 8px;
  }
}

.card {
  .card-title {
    font-size: 1.4rem;
    font-weight: 700;
  }
}

.card-primary:not(.card-outline)>.card-header {
  background-color: $violet;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}

.violet-button {
  background-color: $violet;
  color: $white;

  &:hover {
    background-color: $green;
  }
}

.green-button {
  background-color: $green;
  color: $white;

  &:hover {
    background-color: $violet;
  }
}

.phone-input-styled {
  display: flex !important;
}

select {
  font-size: 12px;
}

.nav-tabs {
  .nav-link {
    display: initial;

    &.active {
      background-color: $green;
      color: $white !important;
      border-radius: 5px;
    }
  }
}

.card-border-green {
  border: 1px solid $green;
}