.finances {
  &__table {
    display: none;
    max-width: 1280px;

    @media(min-width: $tablet) {
      display: flex;
    }

    &-wrapper {
      width: 100%;

      table {
        &>tbody {
          &>tr {
            &:nth-child(2n + 1) {
              background-color: $gray-1;
            }
          }
        }
      }
    }
  }
  
  &__message {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    line-height: 140%;
    font-size: 34px;

    &--green {
      color: $green;
    }

    &--red {
      color: $red-1;
    }

    @media(min-width: $tablet) {
      font-size: 40px;
    }
  }

  &__btn {
    max-width: 200px;
    margin: 40px auto 0;
  }
}