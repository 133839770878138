@use '../../scss/variables' as *;

.test-question  {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 180px;
  padding: 16px 16px 24px 16px;
  border-radius: 6px;
  background-color: $white;
  box-shadow: 0 0 15px 0 rgba($black, .1);

  @media(min-width: $tablet) {
    gap: 42px 12px;
    padding: 30px 20px;
    min-height: 210px;
  }

  &--sm {
    padding: 20px;
    min-height: 58px;
  }

  &__timer {
    display: flex;
    justify-content: flex-end;
    height: fit-content;

    @media(min-width: $tablet) {
      display: block;
      grid-column: 2 / 3;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.3;
    color: $violet;
    text-align: center;
  }

  &__text {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1;
    color: $dark-gray;
    text-align: center;

    @media(min-width: $tablet) {
      font-size: 18px;
      line-height: 1;
    }
  }
}