@use '../../scss/variables' as *;

.attempts-answer-block {
  display: grid;
  grid-template-rows: max-content;
  gap: 6px;

  @media(min-width: $tablet) {
    margin-bottom: 0;
  }

  &__count {
    display: none;

    @media(min-width: $tablet) {
      display: inline-block !important;
      margin-right: 5px;
    }
  }

  &__title,
  &__question,
  &__number {
    color: $dark-1;
    opacity: .6;
  }

  &__question,
  &__number {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.1;
  }

  &__title {
    font-size: 12px;
    line-height: 1;

    @media(min-width: $tablet) {
      display: none;
    }
  }

  &__item {
    display: flex;
    gap: 5px;
  }
}