.icon {
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  color: $dark-gray;
  transition: .4s;

  &--sm {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
  }

  &--md {
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
  }
  
  &--gray {
    color: $gray-line;
  }

  &--green {
    color: $green;
  }

  &--yellow {
    color: $yellow-line;
  }

  &--red {
    color: $red-line;
  }

  &--violet {
    color: $violet;
  }

  &--white {
    color: $white;
  }
}