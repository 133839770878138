.contacts {
  margin: 35px auto;

  &__head {
    @media(min-width: $laptop) {
      display: none;
    }
  }

  &__content {
    margin: 25px auto 0;
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  
    // @media(min-width: $tablet) {
    //   grid-template-columns: repeat(2, 1fr);
    // }
  
    // @media(min-width: $laptop) {
    //   grid-template-columns: repeat(3, 1fr);
    //   gap: 20px 30px;
    // }
  }
}