.attempts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  @media(min-width: $tablet) {
    margin-top: 24px;
    gap: 24px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 820px;
    margin: 0 auto;

    @media(min-width: $tablet) {
      gap: 12px;
    } 
  }

  &__head {
    display: none;

    @media(min-width: $tablet) {
      display: grid;
    }
  }

  &-answers {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media(min-width: $tablet) {
      padding: 12px 20px;
      border-radius: 6px;
      background-color: $white;
      box-shadow: 0 0 15px 0 rgba($black, .1);
    }

    &__head {
      display: none;
      
      @media(min-width: $tablet) {
        display: grid;
        grid-template-columns: 34px repeat(3, 1fr) 20px;
      }

      &:first-child {
        @media(min-width: $tablet) {
          padding-left: 6px;
          padding-right: 10px;
          border-left: 1.5px solid transparent;
          border-right: 1.5px solid transparent;
        }
      }
    }

    &__title {
      font-size: 14px;
      line-height: 1;
      color: $dark-1;
      opacity: .6;
      margin-right: 24px;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}