@use '../../scss/variables' as *;

.homework-card {
  position: relative;
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba($black, .1);
  padding-bottom: 8px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 7px;
    height: 100%;
    border-radius: 9px 0 0 9px;
    background-color: $gray-line;
    transition: .4s;
  }

  &__head {
    display: grid;
    grid-template-columns: 24px 1fr auto auto 24px;
    align-items: center;
    gap: 8px;
    padding: 20px 18px 12px;
    cursor: pointer;

    .btn-light {
      grid-column: 3 / 4;
    }

    .btn-green {
      grid-column: 4 / 5;
    }
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    background-color: $violet;
    color: $white;
    border-radius: 50%;

    @media(min-width: $tablet) {
      font-size: 16px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1;
    color: $dark-gray;

    @media(min-width: $tablet) {
      font-size: 18px;
      line-height: 1;
    }
  }

  &__arrow {
    transform: rotate(0deg);
    margin-left: 4px;
    transition: .4s;
    grid-column: 5 / 6;
  }

  &__body {
    overflow: hidden;
    transition: .4s;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 18px 12px;
  }

  &-block {
    display: flex;
    flex-direction: column;
    gap: 4px;
    line-height: 1;
    color: rgba($dark-1, .6);

    &__title {
      font-size: 14px;
    }

    &__desc {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 600;
      font-size: 16px;

      &-num {
        color: $violet;
      }
    }
  }

  &.open {
    .homework-card {
      &__arrow {
        transform: rotate(-180deg);
      }

      &__head {
        padding-bottom: 12px;
      }
    }
  }

  &.green-line {
    &::before {
      background-color: $green;
    }
  }

  &.yellow-line {
    &::before {
      background-color: $yellow-line;
    }
  }

  &.red-line {
    &::before {
      background-color: $yellow-line;
    }
  }
}