@use '../../scss/variables' as *;

input,
textarea {
	width: 100%;
	margin: 0;
	padding: 0;
	border-radius: 0;
	border: none;
	outline: none;
	background-color: transparent;
	font-family: $font;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: $dark-gray;

	&:-webkit-autofill {
		-webkit-text-fill-color: $dark-gray;
		-webkit-background-clip: text;

		&:focus,
		&::first-line {
			font-size: 16px;
			color: $dark-gray;
		}
	}

	&::placeholder {
		font-size: 16px;
		font-weight: 400;
		line-height: 150%;
		color: $dark-gray-1;
    }
}

.field {
	display: flex;
	flex-direction: column;
	gap: 6px;

	&.required {
		.field__title {
			position: relative;

			&::after {
				content: '*';
				position: absolute;
				color: $red;
				font-size: 12px;
				margin-left: 5px;
			}
		}
	}

	&__title {
		font-weight: 400 !important;
		font-size: 16px;
		line-height: 120%;
		color: $dark-gray-1;
		margin: 0;

		span {
			font-weight: 400;
			font-size: 12px;
		}
	}

	&__input {
		width: 100%;
		padding: 10px 20px;
		background-color: $white;
		border: 1px solid $gray-border;
		border-radius: 10px;
		transition: .6s;

		&:focus,
		&:hover {
			border: 1px solid $violet;
		}
	}

	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
	}
}