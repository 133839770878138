$phone: 568px;
$tablet: 768px;
$laptop: 992px;
$desktop: 1220px;
$desktop-lg: 1440px;

$font: 'Proxima Nova', sans-serif;

$white: #fff;
$black: #000;
$text: #3d3d3d;
$bg: #f7fafc;
$sub-color: #b9b9b9;
$main-color: #000000;
$red: #E9271A;
$red-1: #E45446;
$red-secondary: #FF3B30;
$red-line: #FF6259;

$dark: #454545;
$dark-1: #1D1D1D;
$dark-gray: #5E6D55;
$dark-gray-1: #999CA6;
$dark-gray-2: #576600;

$gray-1: #F9F9F9;
$gray-2: #E9E9E9;
$gray-count: #E6E6E6;
$gray-60: #9E9E9E;
$gray-20: #F5F5F5;
$gray-100: #0A0A0A;
$gray-500: #6D7280;
$gray-border: #DEE6DD;
$gray-border-2: #E5E5E5;
$gray-line: #BBBBBB;
$gray-counter: #FCFCFC;

$green: #97B000;  
$violet: #6b298a;
$link: $violet;
$blue: #3E99DB;
$button-blue: #0a90eb;

$yellow: #EFD910;
$yellow-dark: #FAC334;
$yellow-line: #FFC107;
//green 98b000;