@use '../../../scss/variables' as *;

.course-check {
  position: relative;
  margin-top: 21px;
  width: 100%;

  @media(min-width: $tablet) {
    width: calc((100% - 25px) / 2);
  }

  @media(min-width: $laptop) {
    width: calc((100% - 50px) / 3);
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: none;

    & + .course-check {
      &-content {
        display: flex;
        background-color: rgba($violet, .08);
        border-radius: 9px;
        height: 80px;
        overflow: hidden;
        cursor: pointer;
      
        &::after,
        &::before {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          right: 8px;
          top: 8px;
          transition: .4s;
        }

        &::before {
          background-color: transparent;
          border: 2px solid $green;
        }

        &::after {
          background-image: url('../../../img/icons/check-green.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 20px 20px;
          width: 20px;
          height: 20px;
          opacity: 0;
        }
      
        span {
          position: absolute;
          left: 0;
          top: -21px;
          font-weight: 700;
          font-size: 16px;
          letter-spacing: -0.32px;
          color: $white;
          padding: 5px 10px;
          border-radius: 9px 9px 9px 0px;
          background-color: $violet;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          pointer-events: none;
        }
      }
    }

    &:checked {
      & + .course-check {
        &-content {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}