@use '../../scss/variables' as *;

.info {
  width: 100%;
  padding: 5px 10px;
  background-color: rgba($violet, .2);
  border-radius: 5px;
  font-size: 14px;
  line-height: 120%;
  color: $dark-gray;

  &--lg {
    padding: 10px 15px;
    font-size: 16px;
    text-align: center;
  }
}