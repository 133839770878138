@use '../../../scss/variables' as *;

.card-finances {
  padding: 15px;
  border: 1px solid $gray-1;
  box-shadow: 0px 0px 10px rgba($black, 0.02);
  border-radius: 5px;

  &-top {
    display: flex;
    align-items: center;
    gap: 12px;

    img {
      width: 18px;
      height: 12px;
      flex: 0 0 18px;
      object-fit: contain;
    }

    h3 {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: $dark-gray;
    }
  }

  &__content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &-text {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: rgba($black, .4);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btns {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > .link {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 100% !important;
      padding: 0;
      background-color: transparent;
      width: fit-content;
      margin: 0 auto; 
      height: fit-content;
  
      &:hover {
        background-color: transparent;
      }
    }
  }
}