@use '../../scss/variables' as *;

.error-message {
    margin-top: 20px;
    display: flex;
    font-size: 14px;
    line-height: 120%;
    color: $red;

    & + span {
        margin-top: 5px;
    }
}

.error-field {
    position: absolute;
    margin-top: 4px;
    font-size: 10px;
    line-height: 100%;
    display: flex;
    color: $red;
}