@use '../../../../scss/variables' as *;

.header-mobile {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  z-index: 30;
  padding: 115px 20px;
  pointer-events: none;
  transform: translateX(100%);
  transition: .4s;

  @media(min-width: $tablet) {
    left: initial;
    width: 500px;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    li {
      display: flex;

      a, 
      button {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        color: $green;
        transition: .4s;

        .icon {
          color: $green;
          transition: .4s;
        }

        &:hover {
          color: $violet;

          .icon {
            color: $violet;
          }
        }
      }
    }

    .exit {
      &:hover {
        .icon {
          color: $violet;
        }
      }
      .icon {
        width: 24px;
        height: 24px;
        flex: 0 0 24px;
        color: $green;
        transition: .4s;
      }

      &__text {
        gap: 10px;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        color: $green;
        transition: .4s;
      }
    }
  }
}