@use '../../scss/variables' as *;

.hint-test {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: $violet;
  }

  &__item {
    font-size: 12px;
    line-height: 1;
    color: $violet;
  }
}