.exit {
    display: flex;
    align-items: center;
    transition: .3s;
    cursor: pointer;
  
    &:hover {
      & > .exit__text {
        color: $violet;
      }
    }
  
    .icon {
      transition: .3s;
      color: $violet;
    }
  
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: $black;

      &::after {
        bottom: 0;
      }
    }
  }