@use '../../scss/variables' as *;

.login {
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  padding: 3rem;
  background-color: $white;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem;
  position: relative;
  z-index: 2;

  @media(min-width: $phone) {
    max-width: 400px;
  }

  &-head {
    display: flex;
    align-items: center;
    gap: 15px;

    &__title {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      color: $violet;
      text-transform: uppercase;
      margin: 0;
    }

    img {
      width: 40px;
      height: auto;
    }
  }

  &__form {
    margin-top: 35px;
    position: relative;
  }

  &__btns {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  // h2 {
  //   width: 100%;
  //   text-align: center;
  //   display: block;
  //   margin-bottom: 0.5rem;
  // }
}

// .sign-in-link {
//   margin-top: 30px;

//   a {
//     color: #0162c4;
//     text-decoration: underline 1px #0162c4;
//   }
// }