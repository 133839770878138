.settings {
  margin: 25px auto;
  height: 100%;

  &__content {
    margin-top: 25px;

    @media(min-width: $tablet) {
      margin-top: 35px;
    }
  }

  &-block {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media(min-width: $tablet) {
      gap: 25px;
    }

    &__title {
      color: $dark-gray;
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      border-bottom: 1px solid #F3F3F3;
      padding: 0 0 5px 10px;
    }

    .cards {
      max-width: 750px;
      width: 100%;
      margin: 0 auto;

      @media(min-width: $laptop) {
        max-width: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 30px;
      }
    }
  }
}