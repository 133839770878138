.card-pay {
  padding: 15px;
  font-weight: 400;
  background-color: $white;
  border: 1px solid $gray-1;
  box-shadow: 0px 0px 10px rgba($black, 0.02);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &--start {
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 5px;

      @media(min-width: $tablet) {
        font-size: 10px;
      }
    }

    &--end {
      justify-content: flex-end;
    }

    &--top {
      align-items: start;
    }
  }

  &__coll {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__date {
    font-size: 12px;
    line-height: 100%;
    color: $dark-gray;
  }

  &__sum {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
  }

  &-text {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 10px;
    font-size: 14px;
    line-height: 100%;
    color: rgba($black, .4);

    @media(min-width: $tablet) {
      font-size: 18px;
    }

    &--top {
      margin-top: 5px;
      align-items: flex-start;
    }

    &--wrap {
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 0 5px;
    }
  }

  &-title {
    font-size: 14px;
    line-height: 120%;
    color: rgba($black, .4);
    display: flex;

    @media(min-width: $tablet) {
      font-size: 18px;
    }
  }

  &-desc {
    font-size: 14px;
    line-height: 120%;
    color: $dark-gray;

    @media(min-width: $tablet) {
      font-size: 18px;
    }
  }

  &-box {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .link {
    font-weight: 400; 
    color: rgba($black, .4);

    &:hover {
      color: $violet;

      &::after {
        background-color: $violet;
      }
    }

    &::after {
      background-color: rgba($black, .4);
    }
  }
}