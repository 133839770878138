.program {
  margin: 20px auto;

  &__subtitle {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 18px;
    line-height: 100%;
    color: $violet;
  }

  &__cards {
    margin: 20px auto 0;
    max-width: 550px;
  }
}