@use '../../scss/variables' as *;

.schedule-month {
  width: 100%;

  @media(min-width: $laptop) {
    flex: 0 1 930px;
  }

  .react-calendar {
    border: none;
    line-height: 100%;
    width: 100%;

    @media(min-width: $tablet) {
      padding: 0;
    }

    &__navigation {
      display: flex;
      align-items: center;
      margin: 0 auto;
      max-width: 282px;
      width: 100%;
      height: 32px;

      @media(min-width: $tablet) {
        max-width: 418px;
      }

      @media(min-width: $laptop) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &__arrow {
        border: none;
        margin: 0;
        min-width: auto;
        width: 32px;
        height: 32px;
      }

      &__label {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: auto;
        height: 32px;

        // &:hover,
        // &:focus {
        //   background-color: transparent !important;

        //   span {
        //     color: $green;
        //   }
        // }

        &:disabled {
          background-color: transparent;
        }
        
        &__labelText {
          font-family: $font;
          font-weight: 700;
          font-size: 16px;
          line-height: 1;
          color: $violet;
          text-transform: capitalize;
          transition: .3s;

          @media(min-width: $tablet) {
            font-size: 20px;
            // line-height: 1.6;
          }
        }
      }

      &__arrow {
        position: relative;
        background-color: transparent;
        border-radius: 5px;
        transition: .3s;

        &::after{
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-size: 24px 24px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover,
        &:focus {
          background-color: $gray-border !important;
        }
      }

      &__next-button,
      &__prev-button {
        &::after{
          background-image: url('../../img/icons/arrow-violet.svg');
        }
      }

      &__next2-button,
      &__prev2-button {
        &::after{
          background-image: url('../../img/icons/arrow-double-violet.svg');
        }
      }

      &__next2,
      &__prev {
        &-button {
          margin-left: 10px !important;
        }
      }

      &__prev,
      &__prev2 {
        &-button {
          &::after {
            transform: translate(-50%, -50%) rotate(-180deg);
          }
        }
      }
    }

    &__viewContainer {
      padding-top: 22px;

      @media(min-width: $tablet) {
        padding-top: 56px;
      }
    }

    &__month-view {
      &__weekdays {
        display: none !important;

        @media(min-width: $tablet) {
          display: flex !important;
          font-weight: 700;
          font-size: 10px;
          margin-bottom: 10px;
        }

        &__weekday {
          padding: 0 6px 0 0;

          abbr {
            font-weight: 700;
            font-size: 10px;
            line-height: 1;
            color: $violet;
            text-decoration: none;
          }
        }
      }
      
      &__days {
        flex-direction: column;
        gap: 10px;

        @media(min-width: $tablet) {
          flex-direction: row;
          gap: 0px;
        }

        &__day {
          padding: 0;
          overflow: visible !important;
          background-color: $white;
          transition: .4s;

          @media(min-width: $tablet) {
            padding: 0 6px 6px 0;
          }

          &:nth-child(7n + 1) {
            border-left: 0;
          }

          &--weekend {
            color: $black;

            abbr {
              color: $black;
            }

            &.react-calendar__month-view__days__day--neighboringMonth {
              abbr {
                color: $black;
              }
            }
          }

          &--neighboringMonth {
            display: none;

            @media(min-width: $tablet) {
              display: flex;
              opacity: .6;
            }

            abbr {
              color: $gray-60;
            }
          }
        }
      }

      .react-calendar__tile {
        transition: .4s;

        &--active {
          background: $white;
        }

        &--now {
          background: $white;

          abbr {
            background: transparent;

            @media(min-width: $tablet) {
              
            }
          }

          // &:enabled {
          //   &:hover,
          //   &:focus {
          //     background: $white;

          //     @media(min-width: $tablet) {
          //       background: $violet;
          //     }

          //     abbr {
          //       // color: $white;
          //       // background: $violet;

          //       @media(min-width: $tablet) {
          //         background: transparent;
          //       }
          //     }
          //   }
          // }
        }

        // &.event {
        //   @media(min-width: $tablet) {
        //     background: $green;
        //   }

        //   & > abbr {
        //     color: $white;
        //     background: $green; 

        //     @media(min-width: $tablet) {
        //       background: transparent;
        //     }
        //   }

        //   // &:enabled {
        //   //   &:hover,
        //   //   &:focus {
        //   //     @media(min-width: $tablet) {
        //   //       background: $green;
        //   //     }

        //   //     & > abbr {
        //   //       background: $green;

        //   //       @media(min-width: $tablet) {
        //   //         background: transparent;
        //   //       }
        //   //     }
        //   //   }
        //   // }
        // }

        // &.today {
        //   @media(min-width: $tablet) {
        //     background: $blue !important;
        //   }

        //   & > abbr {
        //     color: $white;
        //     background: $blue !important; 

        //     @media(min-width: $tablet) {
        //       background: transparent !important;
        //     }
        //   }

        //   // &:enabled {
        //   //   &:hover,
        //   //   &:focus {
        //   //     @media(min-width: $tablet) {
        //   //       background: $blue !important;
        //   //     }

        //   //     & > abbr {
        //   //       background: $blue !important;

        //   //       @media(min-width: $tablet) {
        //   //         background: transparent !important;
        //   //       }
        //   //     }
        //   //   }
        //   // }
        // }

        // &.past-day {
        //   display: none;
        // }

        &.current-day {
          .schedule-day {
            &__num {
              background-color: $violet;
              color: $white;
            }
          }
        }
      
        &.choose-day {
          .schedule-day {
            border-color: $green;
          }
        }

        &.past-day {
          .schedule-day {
            &:after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              background-color: rgba($white, .5);
              z-index: 2;
            }
          }
        }
      }
    }
    
    &__tile {
      transition: .4s;

      &:enabled {
        &:hover {
          background-color: $white;
        }
      }

      &--active  {
        color: $black;
      }

      abbr {
        display: none;
      }
    }
  }
}