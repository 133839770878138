.label {
  font-size: 14px;
  line-height: 100%;
  padding: 6px 10px;
  border-radius: 24px;

  &--violet {
    background-color: $violet;
    color: $white;
  }
}