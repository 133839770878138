@use '../../scss/variables' as *;

.modal-general{
  &__container {
    max-width: 650px;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: $dark;
    text-align: center;

    @media(min-width: $tablet) {
      font-size: 32px;
    }

    span {
      font-weight: 600;
      color: $green;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    max-width: 400px;
    width: 100%;

    .btn-violet {
      text-transform: uppercase;
    }
  }
}