*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 156px;
}

html,
body {
  height: 100%;
}

@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 37.5em) {
  html {
    font-size: 50%;
  }
}

body {
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 2.2rem;
  letter-spacing: 0;
  color: $text;
  background: $white;

  @include respond(phone) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  &.open {
    overflow: hidden;
  }
}

fieldset {
  border: none;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

a {
  text-decoration: none;
  color: $black;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

p {
  margin: 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
  margin: 0;
}

svg path {
  fill: currentColor;
}

.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  &--login {
    height: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }
}

.wrapper {
  margin: 146px 0 30px;
  height: 100%;
}

.wrapper-sm {
  margin: 75px 0 30px;
  height: 100%;
}

.hide {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.green {
  color: $green !important;
}

.red {
  color: $red-1 !important;
}

.gray {
  color: $dark-gray-1 !important;
}

.violet {
  color: $violet !important;
}

.underline {
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;

  @media(min-width: $laptop) {
    gap: 20px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($black, .4);
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: .6s;

  &.bg {
    animation: bg-anim .3s linear;
  }
}

@keyframes bg-anim {
  0% {
    background-color: rgba($black, .6);
  }

  50% {
    background-color: rgba($black, .8);
  }
  100% {
    background-color: rgba($black, .4);
  }
}

.circle-status {
  display: flex;
  width: 12px;
  height: 12px;
  flex: 0 0 12px;
  border-radius: 50%;

  &.bg-green {
    background-color: $green;
  }
  
  &.bg-red {
    background-color: $red-secondary;
  }
  
  &.bg-yellow {
    background-color: $yellow-dark;
  }
}

.finances-message {
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 800px;
}

.mb-0 { 
  margin-bottom: 0 !important;
}