@use '../../scss/variables' as *;

.plug {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  max-width: 350px;
  margin: 60px auto;
  text-align: center;
  color: $violet;
  font-size: 28px;
  line-height: 140%;

  @media(min-width: $tablet) {
    max-width: 550px;
  }

  .icon {
    width: 80px;
    height: 80px;
    flex: 0 0 80px;
    color: rgba($violet, .7);
  }
}