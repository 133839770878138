.modal-info {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition: .6s;

  &.open {
    opacity: 1;
    pointer-events: initial;

    .overlay {
      opacity: 1;
      pointer-events: initial;
      cursor: pointer;
    }
  }

  &__container {
    max-width: 500px;
    width: 100%;
    position: relative;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 35px;
    background-color: $white; 
    border-radius: 10px;

    @media(min-width: $tablet) {
      padding: 30px 40px;
    }

    &--gap {
      gap: 30px;
    }
  }

  &-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    position: relative;

    &--row {
      flex-direction: row-reverse;
    }

    &-wrapper {
      width: 100%;

      .btn {
        text-transform: uppercase;
      }

      &--move {
        margin-bottom: 70px;
      }
    }

    .btn {
      margin: 0;
    }
  }

  &__btn {
    margin-top: 30px;
    width: 100%;

    @media(min-width: $tablet) {
      margin-top: 45px;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    color: $violet;
    text-align: center;

    @media(min-width: $tablet) {
      font-size: 32px;
      line-height: 42px;
    }
  }

  &__subtitle {
    margin: 10px 0 0 0;
    color: $dark-gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;

    @media(min-width: $tablet) {
      font-size: 18px;
    }
  }

  &__text {
    margin: 0;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: $dark-gray-2;
    text-align: center;

    a {
      display: inline-flex !important;
      margin: 0 !important;
      font-size: 24px !important;
    }
  }

  &-block {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    &__title {
      font-size: 22px;
      line-height: 120%;
      color: rgba($black, .4);
      margin: 0;
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      color: $black;
      margin: 0;
    }
  }

  &__no {
    font-weight: 400;
    font-size: 14px;
    color: $dark-gray-1;

    &::after {
      background-color: $dark-gray-1;
    }

    &:hover {
      color: $gray-border;

      &::after {
        background-color: $gray-border;
      }
    }

    &.move {
      height: 15px;
    }
  }

  .link {
    display: inline;
    margin: 0 5px;
    font-size: 18px;
  }
}