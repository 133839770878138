@use '../../scss/variables' as *;
@use '../../scss/mixins' as *;

.btn {
  font-family: $font;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  width: 100%;
  height: 45px;
  padding: 12px;
  border: none;
  cursor: pointer;
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @include transition(0.3s);

  &:disabled {
    background-color: $dark-gray-1;
    cursor: not-allowed !important;

    &:hover {
      background-color: $dark-gray-1;
      cursor: initial;
    }
  }

  &:hover {
    background-color: #242e44;
    color: $white;
  }

  &--md {
    height: auto;
    padding: 10px 20px;
  }

  &--fs-md {
    font-size: 14px;
  }

  &--rounded-md {
    border-radius: 28px;
  }

  &.loading {
    position: relative;
    cursor: not-allowed;
    
    .btn {
      &__text,
      &__icon {
        opacity: 0;
        pointer-events: none;
        position: absolute;
      }

      &__spinner {
        opacity: 1;
        pointer-events: initial;
        position: static;
      }
    }

    img {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }
  }
  

  &__text {
    display: flex;
    align-items: center;
    gap: 10px;
    transition: .3s;
    opacity: 1;
    position: static;

    span {
      text-transform: uppercase;
    }
  }

  img {
    display: flex;
    max-height: 24px;
    min-width: 24px;

    + span {
      margin-top: 4px;
    }
  }

  &__spinner {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  &-light {
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    padding: 0;
    color: $dark-gray;
    background-color: transparent;
    height: auto;
    width: auto;

    .btn {
      &__text {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          width: 100%;
          background-color: $dark-gray;
          transition: .4s;
        }
      }
    }

    @media(hover: hover) {
      &:hover {
        background-color: transparent;
        color: $violet;

        .btn {
          &__text {
            &::after {
              left: initial;
              width: 0;
              background-color: $violet;
            }
          }
        }
      }
    }
  }

  &-inverted {
    background-color: white;
    color: black;
    border: 1px solid black;

    &:hover {
      background-color: black;
      color: white;
      border: none;
    }
  }

  &-violet {
    background-color: $violet;
    color: $white;
  
    &:hover {
      background-color: $green;

      @media(max-width: $tablet) {
        background-color: $violet;
      }

      &:disabled {
        background-color: rgba($violet, .4);
      }
    }

    &:disabled {
      background-color: rgba($violet, .4);
    }
  }

  &-green {
    background-color: $green;
    color: $white;
  
    &:hover {
      background-color: $violet;

      &:disabled {
        background-color: rgba($green, .4);
      }
    }

    &:disabled {
      background-color: rgba($green, .4);
    }
  }

  &-white {
    background-color: $white;
    border: 1px solid $green;
    color: $green;
    transition: background-color .4s;
    
    &:hover {
      background-color: $green;
    }
  }
}