@use '../../scss/variables' as *;

.password {
    position: relative;

    &__input {
        padding: 10px 50px 10px 20px;
        margin: 0;
    }

    &__btn {
        position: absolute;
        width: 50px;
        height: 100%;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.6;
        cursor: pointer;
        transition: .3s;

        &:hover {
            opacity: 1;
        }

        &.show {
            opacity: 1;

            .password {
                &__icon {
                    &--open {
                        opacity: 0;
                        pointer-events: none;
                    }

                    &--close {
                        opacity: 1;
                        pointer-events: initial;
                    }
                }
            }
        }
    }

    &__icon {
        position: absolute;
        width: 24px;
        height: 24px;
        flex: 0 0 24px;
        transition: .3s;

        &--open {
            opacity: 1;
            pointer-events: initial;
        }

        &--close {
            opacity: 0;
            pointer-events: none;
        }
    }
}
