.check-icon {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    border-radius: 50%;
    background-color: $violet;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .4s;

    &:hover {
        background-color: $green;
    }

    svg {
        width: 13px;
        height: 13px;
        flex: 0 0 13px;
    }
}