@use '../../../scss/variables' as *;

.schedule-day {
  position: relative;
  font-family: $font;
  display: flex;
  gap: 12px;
  width: 100%;
  padding: 10px 12px;
  border: 1px solid $white;
  border-radius: 6px;
  background-color: $white;
  box-shadow: 0 0 15px 0 rgba($black, 0.08);
  transition: .4s;

  @media(min-width: $tablet) {
    flex-direction: column;
    gap: 6px;
    padding: 8px 8px 6px 8px;
    height: 100%;
  }

  &__num {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    background-color: $white;
    border-radius: 50%;
    color: $violet;
    transition: .4s;
    
    @media(min-width: $tablet) {
      font-size: 12px;
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: $dark-gray;
    text-align: left;
    text-transform: capitalize; 

    @media(min-width: $tablet) {
      display: none;
    }
  }

  &__events {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    @media(min-width: $tablet) {
      gap: 2px;
    }
  }
}