@use '../../scss/variables' as *;

.modal-test {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  z-index: 30;
  opacity: 0;
  pointer-events: none;
  transition: .4s;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 550px;
    width: 100%;
    padding: 24px 12px;
    background-color: $white;
    z-index: 35;

    @media(min-width: $tablet) {
      padding: 30px 24px;
      gap: 36px;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    color: $violet;
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;

    .textarea {
      &__wrapper {
        height: 130px;
      }
    }

    button {
      text-transform: uppercase;
      height: 42px;
    }
  }

  &__plug {
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    color: $dark-gray;
    text-align: center;
    padding: 40px 0;
  }

  &.open {
    opacity: 1;
    pointer-events: initial;

    .overlay {
      opacity: 1;
      pointer-events: initial;
    }
  }
}