.table {
  width: 100%;
  border-radius: 15px;
  border: 1px solid $gray-border;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &-block {
    width: 100%;
    border-spacing: 0;
    box-sizing: border-box;
    border-collapse: collapse;
    background-color: $white;
    border-radius: 15px;
  }

  &-head {
    &__cell {
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: $dark-gray-2;
      padding: 10px 15px;
      border-top: none;
      vertical-align: middle;
      border-right: 1px solid $gray-border;
      text-align: left;

      &:last-child {
        border-right: none;
      }

      &--center {
        text-align: center;
      }
    }
  }

  &-body {
    &__cell {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: $dark;
      padding: 10px 15px;
      vertical-align: middle;
      border-right: 1px solid $gray-border;
      border-top: 1px solid $gray-border;
      position: relative;
      transition: .3s;

      &:last-child {
        border-right: none;
      }

      &--bold {
        font-weight: 600;
        font-size: 18px;
      }

      &--center {
        text-align: center;
      }

      &-row {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .btn {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 140px;
    margin: 0 auto;
  }

  &__link {
    font-weight: 500;
    line-height: 100%;
    font-size: 16px;
    padding: 0;
    background-color: transparent;
    width: fit-content;
    margin: 0 auto !important; 
    height: fit-content !important;

    &:hover {
      background-color: transparent;
    }
  }

  .check-icon {
    margin: 0 auto;
  }

  .programs-link {
    @media(min-width: $tablet) {
      justify-content: center;
    }

    .link {
      @media(min-width: $tablet) {
        font-size: 18px;
      }
    }
  }

  .progress {
    &__count {
      @media(min-width: $tablet) {
        font-size: 18px;
      }
    }
  }

  &-status {
    display: flex;
    align-items: center;
    gap: 10px;

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      flex: 0 0 16px;
      border-radius: 50%;
    }

    &.bg-green {
      &::before {
        background-color: $green;
      }
    }
    
    &.bg-red {
      &::before {
        background-color: $red-secondary;
      }
    }
    
    &.bg-yellow {
      &::before {
        background-color: $yellow-dark;
      }
    }
  }

  &-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .link {
      margin: 0 !important;
    }
  }
}