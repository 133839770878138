@use '../../../scss/variables' as *;

.tabs-round {
  display: flex;
  align-items: center;
  gap: 10px;

  li {
    display: flex;

    button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 34px;
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;
      color: $green;
      background-color: $white;
      border: 1px solid $green;
      cursor: pointer;
      transition: .4s;

      &:hover {
        background-color: rgba($green, .15);
        color: $green;
      }

      &.active {
        background-color: $green;
        color: $white;
      }
    }
  }
}