@use '../../scss/variables' as *;

.notification-item {
  position: relative;
  display: flex;
  gap: 12px;
  padding: 16px 14px;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba($black, .1);
  border: 1.5px solid transparent;
  opacity: .8;
  transition: .4s;
  cursor: pointer;

  &.unread {
    border-color: $green;
    opacity: 1;
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1;
    color: $dark-gray;
    margin-bottom: 6px;

    span {
      &:last-child {
        margin-left: 5px;
        color: $violet;
      }
    }
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    color: $dark-1;
    opacity: .6;
    margin-bottom: 12px;
  }

  &__date {
    font-size: 14px;
    line-height: 1;
    color: $dark-1;
    opacity: .6;
  }

  &__content {
    flex: 1;
  }

  &__count {
    position: absolute;
    top: -6px;
    right: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: $white;
    background-color: $violet;
    border-radius: 50%;
  }
}