@use '../../scss/variables' as *;

.course-name {
  display: flex;
  align-items: center; 
  flex-wrap: wrap;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: $dark-gray;
  
  @media(min-width: $tablet) {
    align-items: center;
    color: $dark;
  }

  &--center {
    justify-content: center;
  }

  &--bg-violet {
    padding: 4px 10px;
    border-radius: 9px;
    background-color: $violet;
    max-width: 225px;
    color: $white;
    align-items: flex-start;

    @media(min-width: $tablet) {
      max-width: fit-content;
      padding: 8px 12px;
    } 

    img {
      margin-top: 4px;
    }
  }

  img {
    width: 18px;
    height: 12px;
    flex: 0 0 18px;
    object-fit: contain;

    @media(min-width: $tablet) {
      width: 22px;
      height: 16px;
      flex: 0 0 22px;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.32px;

    @media(min-width: $tablet) {
      font-size: 18px;
    }

    &--bold {
      font-weight: 700;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: -0.2px;

    @media(min-width: $tablet) {
      margin-top: 5px;
      font-size: 12px;
    }
  }
}