@use '../../scss/variables' as *;

.title-auth {
	max-width: 500px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 40px;

	&--center {
		max-width: 100%;
		height: 50vh;

		h1 {
			font-size: 52px;

			@media(max-width: $tablet) {
				font-size: 36px;
			}
		}

		h2 {
			font-size: 46px;

			@media(max-width: $tablet) {
				font-size: 30px;
			}
		}
	}

	h1, h2 {
		line-height: 1;
		text-align: center;
		color: $violet;
	}

	h1 {		
		font-weight: 700;
		font-size: 46px;

		@media(max-width: $tablet) {
			font-size: 34px;
		}
	}

	h2 {
		font-weight: 600;
		font-size: 40px;

		@media(max-width: $tablet) {
			font-size: 30px;
		}
	}
}