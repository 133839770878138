@use '../../scss/variables' as *;

.notification-head {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  padding: 12px 18px;
  box-shadow: 0 0 15px 0 rgba($black, .1);

  @media(min-width: $tablet) {
    padding: 30px 24px 0;
    box-shadow: none;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    color: $violet;

    @media(min-width: $tablet) {
      margin-right: 44px;
    }
  }

  &__close {
    @media(min-width: $tablet) {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}