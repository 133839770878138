@use '../../../scss/variables' as *;

.pay-btns {
  gap: 15px !important;

  @media(min-width: $tablet) {
    gap: 20px 30px !important;
  }

  &-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    
    @media(min-width: $tablet) {
      width: calc((100% - 30px) / 2);
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: rgba($black, .4);
      padding-left: 15px;
    }
  }
}