@use '../../scss/variables' as *;

.attempts-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 6px;
  background-color: $white;
  padding: 16px 12px 20px;
  border-radius: 6px;
  box-shadow: 0 0 15px 0 rgba($black, .1);
  color: $dark-gray;

  @media(min-width: $tablet) {
    grid-template-columns: repeat(9, 1fr);
    padding: 20px 24px;
    gap: 16px 10px;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: .9;

    @media(min-width: $tablet) {
      grid-column: span 6;
    } 
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;

    @media(min-width: $tablet) {
      justify-content: flex-start;
      gap: 4px;
    }

    &-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 1;

      @media(min-width: $tablet) {
        color: $dark-1;
        opacity: .6;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media(min-width: $tablet) {
      grid-column: span 2;
      grid-row:  2 / 3;
    }

    &:nth-of-type(2) {
      span {
        display: none;

        @media(min-width: $tablet) {
          display: block;
        }
      }
    }

    &:nth-of-type(3) {
      @media(min-width: $tablet) {
        margin: 0 auto;
      }
    }

    &:nth-of-type(4) {
      margin: 0 0 0 auto;
    }
    
    span {
      line-height: 1;
      color: $dark-1;
      opacity: .6;

      &:first-child {
        font-size: 14px;
      }

      &:last-child {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }

  &__tab {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    color: $green;
    border: 1px solid $green;
    background-color: $white;
    border-radius: 34px;
    padding: 10px;
    height: fit-content;
    cursor: pointer;
    transition: .4s;

    @media(min-width: $tablet) {
      grid-column: span 3;
      grid-row: 3 / 4;
    }

    &.active {
      color: $white;
      background-color: $green;
    }
  }
}