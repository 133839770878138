@use '../../scss/variables' as *;

.progressbar {
  display: flex;
  align-items: center;
  gap: 10px;

  &__line {
    position: relative;
    height: 10px;
    width: 100%;
    background-color: $gray-count;
    border-radius: 5px;

    span {
      position: absolute;
      height: 100%;
      width: 60%;
      border-radius: 5px;
      background-color: $green;
    }
  }

  &__count {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    color: $dark-gray;
  }
}