@use '../../scss/variables' as *;

.burger {
  position: relative;
  width: 20px;
  height: 8px;
  display: flex;
  transition: .75s;
  cursor: pointer;

  &__line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 10px;
    background-color: $violet;
    transition: .4s;

    &--first {
      top: 0;
      animation: burger-first-default .4s forwards;
    }

    &--second {
      bottom: 0;
      animation: burger-second-default .4s forwards;
    }
  }
}

@keyframes burger-first-default {
  0% {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }

  50% {
    top: 50%;
    transform: translateY(-50%) rotate(0);
  }

  100% {
    top: 0;
    transform: translateY(0) rotate(0);
  }
}

@keyframes burger-second-default {
  0% {
    bottom: 50%;
    transform: translateY(50%)rotate(-45deg);
  }

  50% {
    bottom: 50%;
    transform: translateY(50%)rotate(0);
  }

  100% {
    bottom: 0;
    transform: translateY(0) rotate(0);
  }
}

@keyframes burger-first-open {
  0% {
    top: 0;
    transform: translateY(0) rotate(0);
  }

  50% {
    top: 50%;
    transform: translateY(-50%) rotate(0);
  }

  100% {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
}

@keyframes burger-second-open {
  0% {
    bottom: 0;
    transform: translateY(0) rotate(0);
  }

  50% {
    bottom: 50%;
    transform: translateY(50%)rotate(0);
  }

  100% {
    bottom: 50%;
    transform: translateY(50%)rotate(-45deg);
  }
}