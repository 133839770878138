@use '../../scss/variables' as *;

.course-hint {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__block {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0 0 15px 0 rgba($black, .1);
  }

  &__text {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.1;
    text-align: left;
    color: $dark-gray;
  }
}