@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/ProximaNova-Black.eot');
    src: local('../../fonts/Proxima Nova Black'), local('../../fonts/ProximaNova-Black'),
        url('../../fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/ProximaNova-Black.woff2') format('woff2'),
        url('../../fonts/ProximaNova-Black.woff') format('woff'),
        url('../../fonts/ProximaNova-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/ProximaNova-Extrabld.eot');
    src: local('../../fonts/Proxima Nova Extrabold'), local('../../fonts/ProximaNova-Extrabld'),
        url('../../fonts/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/ProximaNova-Extrabld.woff2') format('woff2'),
        url('../../fonts/ProximaNova-Extrabld.woff') format('woff'),
        url('../../fonts/ProximaNova-Extrabld.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/ProximaNovaT-Thin.eot');
    src: local('../../fonts/Proxima Nova Thin'), local('../../fonts/ProximaNovaT-Thin'),
        url('../../fonts/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/ProximaNovaT-Thin.woff2') format('woff2'),
        url('../../fonts/ProximaNovaT-Thin.woff') format('woff'),
        url('../../fonts/ProximaNovaT-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/ProximaNova-Light.eot');
    src: local('../../fonts/Proxima Nova Light'), local('../../fonts/ProximaNova-Light'),
        url('../../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/ProximaNova-Light.woff2') format('woff2'),
        url('../../fonts/ProximaNova-Light.woff') format('woff'),
        url('../../fonts/ProximaNova-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/ProximaNova-Bold.eot');
    src: local('../../fonts/Proxima Nova Bold'), local('../../fonts/ProximaNova-Bold'),
        url('../../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('../../fonts/ProximaNova-Bold.woff') format('woff'),
        url('../../fonts/ProximaNova-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/ProximaNova-Regular.eot');
    src: local('../../fonts/Proxima Nova Regular'), local('../../fonts/ProximaNova-Regular'),
        url('../../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('../../fonts/ProximaNova-Regular.woff') format('woff'),
        url('../../fonts/ProximaNova-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/ProximaNova-Semibold.eot');
    src: local('../../fonts/Proxima Nova Semibold'), local('../../fonts/ProximaNova-Semibold'),
        url('../../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('../../fonts/ProximaNova-Semibold.woff') format('woff'),
        url('../../fonts/ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}