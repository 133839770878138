.test {
  margin-top: 20px;
  height: 100%;

  @media(min-width: $tablet) {
    margin-top: 36px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    height: calc(100% - 85px);
    margin: 18px auto 0;

    @media(min-width: $tablet) {
      margin-top: 24px;
      display: block;
    }

    &--lg {
      max-width: 820px;
    }
  }

  &__question {
    margin-top: 30px;

    @media(min-width: $tablet) {
      margin-top: 24px;
    }
  }

  &__conformity {
    margin-top: 20px;
    margin-bottom: 24px;
    display: flex;
    gap: 26px;

    @media(min-width: $tablet) {
      margin-top: 24px;
      gap: 20px;
    }

    &-answers {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(100% + 12px);
        width: 2px;
        height: 100%;
        background-color: $gray-count;

        @media(min-width: $tablet) {
          display: none;
        }
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }

    &-plug {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.4;
      color: $dark-gray;
      text-align: center;
      min-height: 400px;
    }
  }

  &__hint {
    margin-top: 10px;
  }

  &__field {
    margin-top: 20px;
    margin-bottom: 20px;
    flex: 1;

    @media(min-width: $tablet) {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .textarea {
      &__wrapper {
        height: 120px;

        @media(min-width: $tablet) {
          height: 46px;
        }

        textarea {
          line-height: 1.1;

          @media(min-width: $tablet) {
            line-height: 1.5;
          }
        }
      }

      &.blink {
        .textarea {
          &__wrapper {
            animation: blinkShadow .3s ease-in-out 5;
          }
        }
      }

      &.incorrect {
        .textarea {
          &__wrapper {
            border-color: $red-line;
          }
        }
      }

      &.correct {
        .textarea {
          &__wrapper {
            border-color: $green;
          }
        }
      }
    }

    .error-field {
      position: static;
      padding-left: 12px;
      font-size: 12px;
      line-height: 1.1;
      color: $red-line;
    }
  }

  &__answers {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;

    @media(min-width: $tablet) {
      gap: 10px;
      margin-top: 22px;
    }
  }

  &__error {
    margin-bottom: 20px;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: auto;

    @media(min-width: $tablet) {
      margin-top: 0;
    } 

    .btn-green {
      text-transform: uppercase;
    }
    .btn-light {
      font-size: 12px;
    }
  }

  &__plug {
    max-width: 500px;
    width: 100%;
    font-weight: 600;
    font-size: 28px;
    line-height: 1;
    color: $dark-gray;
    text-align: center;
    margin: 20px auto 40px;

    @media(min-width: $tablet) {
      font-size: 32px;
    }

    .btn-green {
      margin-top: 30px;
      text-transform: uppercase;
      height: 42px;
    }
  }
}

@keyframes blinkShadow {
  0% { border-color: $green; box-shadow: 0 0 0 transparent; }
  50% { border-color: $green; box-shadow: 0 0 30px rgba($green, 1); }
  100% { border-color: $green; box-shadow: 0 0 0 transparent; }
}