@use '../../scss/variables' as *;

.switcher {
  display: flex;

  label {
    display: block;
    position: relative;
    width: 60px;
    height: 30px;
    background-color: rgba($dark,.4);
    transition-property: background-color;
    border-radius: 100px;
    text-indent: -9999px;
    transition: .4s;
    cursor: pointer;
  
    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      background-color: $white;
      border-radius: 90px;
      transition: 0.4s;
    }
  
    &:active {
      &::after {
        width: 60px;
      }
    }
  }

  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked {
      & + label {
        background-color: $violet;

        &:after {
          left: calc(100% - 5px);
          transform: translateX(-100%);
        }
      }
    }
  }
}