@use '../../scss/variables' as *;

.select {
  &--is-disabled {
    cursor: not-allowed !important;
    color: $gray-border !important;

    .select__value-container {
      cursor: not-allowed !important;
    }
  }

  &__control {
    background-color: $white !important;
    border-color: $gray-1 !important;
    border-radius: 5px !important;
    transition: all 400ms !important;
    cursor: pointer;

    @media(min-width: $tablet) {
      border-color: $gray-border !important;
    }
    
    &--is-disabled {
      cursor: not-allowed !important;
      color: $gray-border !important;
    }

    &.select__control--is-focused,
    &.select__control--menu-is-open {
      border-color: $gray-border !important;
      box-shadow: none !important;
    }

    &.select__control--menu-is-open {
      .select {
        &__indicator {
          transform: rotate(-180deg);
        }
      }
    }

    &:hover,
    &:focus,
    &:active {
      border-color: $gray-border;
      box-shadow: none !important;
    }
  }

  &__value-container {
    padding: 10px !important;
    cursor: pointer;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__indicator {
    transform: rotate(0);
    transition: .4s !important;

    &--is-disabled {
      color: $gray-border;
    }
  }

  &__placeholder {
    color: rgba($black, .4) !important;

    &--is-disabled {
      color: $gray-border;
    }
  }

  &__single-value,
  &__input-container {
    color: $dark !important;

    &--is-disabled {
      color: $gray-border !important;
    }
  }

  &__placeholder,
  &__single-value,
  &__input-container {
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;

    @media(min-width: $tablet) {
      font-size: 16px;
    }

    &--is-disabled {
      color: $gray-border;
    }
  }

  &__menu {
    box-shadow: 0 0 0 1px $gray-border, 0 4px 11px $gray-border !important;
    border-radius: 5px !important;

    &-list {
      padding: 0 !important;
    }
  }

  &__option {
    transition: .4s;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 100%;
    padding: 10px 15px !important;
    max-height: 160px;
    color: $dark-gray !important;
    cursor: pointer !important;

    @media(min-width: $tablet) {
      font-size: 16px !important;
    }

    &.select__option--is-selected {
      background-color: rgba($violet, .2) !important;
      color: $dark !important;
    }

    &.select__option--is-focused {
      background-color: rgba($green, .2) !important;
      color: $dark !important;
    }
  }

  &--is-disabled {
    .select__dropdown-indicator {
      color: $gray-border !important;
    }
  }
}