.link {
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: $dark-gray;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  transition: .3s;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $dark-gray;
    transition: .3s;
  }

  &:hover {
    color: $violet;

    &::after {
      left: initial;
      right: 0;
      width: 0;
      background-color: $violet;
    }
  }

  &--underline {
    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $violet;
      transition: .3s;
    }
  
    &:hover {
      color: $violet;
  
      &::after {
        width: 100%;
        left: initial;
        right: 0;
        background-color: $violet;
      }
    }
  }

  &--violet {
    color: $violet;

    &::after {
      background-color: $violet;
    }
  }

  &--lg {
    font-size: 20px !important;

    &::after {
      height: 2px;
    }
  }

  &--tt-sm {
    text-transform: lowercase;
  }
}