.homework {
  display: grid;
  gap: 18px;
  margin-top: 20px;
  padding: 0 12px;

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
  }
}