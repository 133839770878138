.event-green {
    background-color: rgba($green, .1);
  
    &::before {
      background-color: $green;
    }
  
    .card-event {
      &-time {
        &::after {
          background-color: $green;
        }
      }
    }
  }
  
  .event-yellow {
    background-color: rgba($yellow, .1);
  
    &::before {
      background-color: $yellow;
    }
  
    .card-event {
      &-time {
        &::after {
          background-color: $yellow;
        }
      }
    }
  }
  
  .event-blue {
    background-color: rgba($blue, .1);
  
    &::before {
      background-color: $blue;
    }
  
    .card-event {
      &-time {
        &::after {
          background-color: $blue;
        }
      }
    }
  }