@use '../../scss/variables' as *;
@use '../../scss/mixins' as *;

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    width: 30px;
    height: 30px;
    
    & .path {
      stroke: $violet;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
    
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}