@use '../../scss/variables' as *;

.loader {
  display: flex;
  justify-content: center;
  margin-top: 80px;

  @media(min-width: $laptop) {
    margin-top: 150px;
  }
}

.loader span {
  width: 48px;
  height: 48px;
  border: 2px solid $violet;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @media(min-width: $laptop) {
    width: 100px;
    height: 100px;
  } 
}
.loader span::after,
.loader span::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  background: $green;
  width: 7px;
  height: 7px;
  transform: translate(150%, 150%);
  border-radius: 50%;

  @media(min-width: $laptop) {
    width: 15px;
    height: 15px;
  } 
}
.loader span::before {
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  transform: translate(-150%, -150%);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}