@use '../../scss/variables' as *;

.auth {
	padding: 20px 0 60px;

	&__form {
		margin: 38px auto 0;
    max-width: 500px;

    &--full {
      max-width: 1000px;
    }
	}

	&__btns {
    max-width: 500px;
    margin: 30px auto 0;
		display: flex;
    flex-direction: column;
    gap: 15px;
	}

  &__text {
    display: flex;
    justify-content: center;
    gap: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $dark-gray;

    .link {
      font-size: 16px;
      line-height: 20px;

      &::after {
        bottom: -2px;
      }
    }

    &--sm {
      margin-top: 50px;

      .link {
        font-size: 14px;
        line-height: 14px;
  
        &::after {
          bottom: -4px;
        }
      }
    }
  }

  &__wrapper {
    max-width: 500px;
    margin: 0 auto;

    .auth__btns {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 80%;

        @media(max-width: $tablet) {
          width: 100%;
        }
      }
    }

    &--full {
      max-width: 100%;
    }
  }

  &__subtitle {
    max-width: 300px;
    margin: 20px auto 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: $gray-500;
  }

  &-group {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &:first-child {
      margin-top: 0;
    }

    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      color: $gray-500;
    }

    .error-message {
      margin-top: 0;
    }
  }
}