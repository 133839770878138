@use '../../scss/variables' as *;

.alert {
    position: fixed;
    top: 40px;
    right: -100%;
    max-width: 300px;
    background-color: $violet;
    color: $white;
    padding: 10px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    z-index: 10;
    margin: 0;
    border: none;
    transition: .4s;

    &.open {
        right: 20px;
    }
}