@use '../../scss/variables' as *;

.copy-btn {
  display: flex;
  align-items: center;
  gap: 5px;

  @media(min-width: $tablet) {
    justify-content: center;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: $green;
    border-radius: 10px;
    transition: .4s;

    .icon {
      color: $white;
    }

    @media(hover: hover) {
      &:hover {
        background-color: $violet; 
  
        .icon {
          color: $white;
        }
      }
    }
  }

  &__timer {
    display: flex;
    align-items: center;
    gap: 3px;
    white-space: nowrap;
    width: 68px;
    font-size: 14px;
  }
}