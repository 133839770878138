@use '../../scss/variables' as *;

.code {
    position: relative;
    width: 100%;
    display: flex;
    border: 1px solid #DEE6DD;
    border-radius: 10px;
    background-color: $white;
    padding: 10px 16px 10px 20px;
    overflow: hidden;

    &__input {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $text;
        background-color: transparent;
        outline: none;
        padding: 0 15px 0 0;
        margin: 0;
        border: 0;

        &:disabled {
            opacity: 0.6;
            cursor: default;

            &+.code__info {
                opacity: 0.6;

                & > .code__text {
                    cursor: default !important;
                }
            }
        }
    }

    &__info {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.wait {
            .code {
                &__text {
                    &[data-code="send"] {
                        position: static;
                        opacity: 1;
                        pointer-events: initial;
                    }

                    &[data-code="timer"] {
                        position: absolute;
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    &__text {
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: $violet;
        white-space: nowrap;
        cursor: pointer;

        &[data-code="send"] {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }

        &[data-code="timer"] {
            font-style: italic;
            color: $gray-border;
            position: static;
            opacity: 1;
            pointer-events: initial;
        }
    }
}