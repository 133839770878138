.form {
  &--center {
    .error-message {
      text-align: center;
    }
  }

  &__field {
    margin-top: 25px;

    &--less {
      margin-top: 15px;
    }
  }

  &__actions {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__remember {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $gray-500;
    margin: 0;

    &--bold {
      font-weight: 700;
    }

    &--center {
      text-align: center;
    }

    span {
      font-weight: 700;
    }
  }

  &__row {
    display: flex;
    gap: 15px;
  }

  &-block {
    position: relative;
    width: 100%;
  }

  &__message {
    margin-top: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: $dark-gray-2;

    span {
      font-weight: 700;
    }

    &--green {
      padding: 10px;
      background-color: rgba($green, .2);
      color: $dark;
      border-radius: 5px;
    }
  }
}