@use '../../scss/variables' as *;

.notification-message {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 6px;
  padding: 14px;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0 0 15px 0 rgba($black, .1);
  width: 80%;

  @media(min-width: $tablet) {
    width: 60%;
  }

  &__name {
    line-height: 1;

    span {
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      color: $dark-1;
      opacity: .6;

      &:last-child {
        color: $violet;
        margin-left: 4px;
        opacity: 1;
      }
    }
  }

  &__time {
    font-size: 12px;
    line-height: 1.3;
    color: $dark-1;
    opacity: .4;
  }

  &__text {
    font-size: 14px;
    line-height: 1.5;
    color: $text;
  }

  &.send {
    background-color: $green;
    margin-left: auto;

    .notification-message {
      &__name {
        span {
          color: $white;
          opacity: 1;

          &:last-child {
            color: $white;
            opacity: .6;
          }
        }
      }
      
      &__time,
      &__text {
        color: $white;
      }
    }
  }
}