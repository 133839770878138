@use '../../scss/variables' as *;

.contacts-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background-color: rgba($violet, 0.08);
  border-radius: 9px;
  padding: 22px 8px;

  @media(min-width: $laptop) {
    padding: 22px;
  }

  &-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    color: $white;
    padding: 8px 10px;
    border-radius: 34px;
    background-color: $green;
  }

  &-content {
    display: flex;
    align-items: center;
    gap: 15px;

    &__col {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &-icon {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .4s;

    &:hover {
      background-color: $bg;
    }
    .icon {
      width: 34px;
      height: 34px;
      flex: 0 0 34px;
    }
  }

  &-phone {
    color: $green;
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.32px;
  }

  &-message {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    color: $green;

    .icon {
      color: $green;
    }
  }

  &-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    padding: 8px 10px;
    border-radius: 34px;
    color: $green;
    background-color: $white;
    cursor: pointer;
    transition: .4s;

    &:hover {
      color: $white;
      background-color: $green;
    }
  }
}