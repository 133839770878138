@use '../../scss/variables' as *;

.attempts-hint {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__block {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__text {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.1;
    text-align: left;
    color: $dark-gray;

    &:before {
      content: '';
      width: 15px;
      height: 2px;
    }

    &--green {
      &::before {
        background-color: $green;
      }
    }

    &--red {
      &::before {
        background-color: $red-secondary;
      }
    }

    &--gray {
      &::before {
        background-color: rgba(#1E1D1D, .4);
      }
    }
  }
}