.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid #F3F3F3;

  &.open {
    .header {
      &-mobile {
        transform: translateX(0);
        pointer-events: initial;

        & + .overlay {
          opacity: 1;
          pointer-events: initial;
          cursor: pointer;
        }
      }
    }

    .burger {
      &__line {
        &--first {
          animation: burger-first-open .4s forwards;
        }

        &--second {
          animation: burger-second-open .4s forwards;
        }
      }
    }
  }

  &-top {
    padding: 12px 0;
    border-bottom: 1px solid #F3F3F3;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
    }

    .exit {
      display: none;

      @media(min-width: $laptop) {
        display: flex;
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    width: 100%;

    .btn {
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      padding: 4px 15px;
      height: 35px;
      max-width: 220px;

      span {
        display: none;

        @media(min-width: $tablet) {
          display: inline;
        }
      }
    }

    &__notification {
      position: relative;
      z-index: 35;
    }
  }

  &-user {
    display: flex;
    align-items: center;
    gap: 6px;
    white-space: nowrap;

    @media(hover: hover) {
      &:hover {
        color: $green;

        @media(min-width: $tablet) {
          color: $violet;
        }

        svg {
          color: $green !important;

          @media(min-width: $tablet) {
            color: $violet !important;
          }
        }
      }
    }

    svg {
      color: $green;

      @media(min-width: $tablet) {
        color: $violet;
      }
    }

    &__info {
      // display: none;
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
      color: $green;

      @media(min-width: $tablet) {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: inherit;
      }

      &::after {
        bottom: 0;
      }
    }
  }

  &-burger {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 31;

    .burger {
      margin-top: 4px;
    }
  }

  &-menu {
    max-width: 700px;
    color: $white;
    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    margin: 0 0 0 auto;

    @media(min-width: $tablet) {
      gap: 10px;
    }

    &__item {
      display: flex;
      width: 100%;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 100%;
        color: $green;
        border: 1px solid $green;
        border-radius: 34px;
        padding: 4px 10px;
        width: 100%;
        transition: .4s;
  
        .icon {
          color: $green;
          transition: .4s;
        }
  
        &.active,
        &:hover {
          color: $white;
          background-color: $green;
  
          .icon {
            color: $white;
          }
        }
      }
    }
  }
}