.finances-pay {
  margin: 25px auto 0;

  &__content {
    margin-top: 20px;

    @media(min-width: $laptop) {
      max-width: 75%;
      margin: 30px auto 0;
    }    
  }

  &__cards {
    margin-top: 20px;

    @media(min-width: $tablet) {
      display: none;
    }
  }

  &__table {
    display: none;

    @media(min-width: $tablet) {
      display: block;
      max-width: 1280px;
      margin-top: 20px;
    }

    &-wrapper {
      width: 100%;

      &--sm {
        min-width: 1200px;

        table {
          tbody {
            tr {
              
              &:nth-child(2n + 1) {
                background-color: $gray-1;
              }

              td {
                font-size: 14px;
  
                .course-name {
                  font-size: 14px;
  
                  img {
                    margin: 0;
                  }
                }
  
                button {
                  font-size: 14px;
                }
              }
            }
          
            .link {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  &__select {
    margin-top: 25px;

    @media(min-width: $tablet) {
      max-width: 475px;
      width: 100%;
    }

    .error-message {
      margin-top: 5px;
    }
  } 

  &__btns {
    margin: 25px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    &--center {
      @media(min-width: $laptop) {
        flex-wrap: nowrap;
        max-width: 75%;
        margin: 35px auto 0;
      }
    }
  }

  &__btn {
    margin: 20px 0 0 0;

    @media(min-width: $tablet) {
      max-width: 250px;
      margin: 20px 0 0 auto;
    }

    .btn {
      height: 35px;
    }
  }

  &__link {
    font-weight: 500;
    line-height: 100%;
    font-size: 16px;
  }

  &__contract {
    margin-top: 35px;

    @media(min-width: $laptop) {
      width: 75%;
      margin: 35px auto 0;
    }
  }

  &-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
    }

    &__desc {
      display: flex;
      align-items: center;
      gap: 5px;

      svg {
        color: $violet;
      }
    }
  }

  .course-name {
    font-size: 16px;
  }
}