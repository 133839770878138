.btn-download {
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: $green;
    transition: .4s;
  }

  &:hover {
    svg {
      color: $violet !important;
    }
  }
}