.modal {
  position: fixed;
  left: -200%;
  top: 146px;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 146px);
  background-color: $white;
  padding: 38px 0 0 0;
  opacity: 0;
  z-index: 0;
  pointer-events: none;
  transition: .4s;

  &.open {
    opacity: 1;
    left: 0;
    z-index: 5;
    pointer-events: initial;
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__content {
    margin: 28px auto 0;
  }

  &__cards {
    @media(min-width: $tablet) {
      display: none;
    }
  }

  &__table {
    display: none;

    @media(min-width: $tablet) {
      display: block;
      max-width: 1280px;
    }

    &-wrapper {
      width: 100%;
    }
  }

  &__content {
    padding: 0 15px 25px;
    overflow-y: auto;
    height: calc(100% - 60px);
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
}