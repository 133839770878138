@use '../../scss/variables' as *;

.notification {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  z-index: 30;
  transition: .4s;
  pointer-events: none;

  @media(min-width: $tablet) {
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  &__container {
    height: 80%;
    width: 100%;
    background-color: $white;
    box-shadow: 0 0 15px 0 rgba($black, .1);
    z-index: 35;
    transform: translateY(100%);
    transition: .4s;

    @media(min-width: $tablet) {
      max-height: 800px;
      height: 100%;
      max-width: 820px;
      border-radius: 0;
      transform: translateY(0);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 52px);
    overflow: hidden;

    @media(min-width: $tablet) {
      height: calc(100% - 58px);
    }
  }

  &__messages {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    overflow-y: auto;
    padding: 18px 12px;
    min-height: 0;

    @media(min-width: $tablet) {
      padding: 36px 24px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 12px 20px;
    margin-top: auto;

    @media(min-width: $tablet) {
      padding: 0 24px 20px;
      gap: 6px;
    }
  }

  &__field {
    position: relative;
    width: 100%;

    .textarea {
      width: 100%;
  
      &__wrapper {
        height: 44px;
        padding: 12px 20px;
        border-radius: 20px;
  
        @media(min-width: $tablet) {
          height: 46px;
        }
  
        textarea {
          line-height: 1.1;
  
          &::placeholder {
            line-height: 1.1;
          }
        }
      }
    }

    .error-field {
      position: static;
      padding-left: 12px;
    }
  }

  &__send {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    flex: 0 0 44px;
    border-radius: 50%;
    background-color: $green;
    cursor: pointer;
    transition: .4s;

    @media(min-width: $tablet) {
      width: auto;
      height: 42px;
      border-radius: 28px;
      padding: 16px;
    }

    span {
      display: none;

      @media(min-width: $tablet)  {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 1;
        color: $white;
        text-transform: uppercase;
        text-align: center;
      }
    }

    .icon {
      @media(min-width: $tablet) {
        display: none;
      } 
    }

    @media(hover: hover) {
      &:hover {
        background-color: $violet;
      }
    }
  }

  &-label {
    margin-top: 6px;
    display: block;
    font-size: 14px;
    line-height: 1;
    color: $dark-1;
    opacity: .6;
    text-align: right;
  }

  &__plug {
    margin: 20% auto auto auto;
    font-weight: 600;
    font-size: 28px;
    line-height: 1;
    color: $dark-gray;
  }

  &.open {
    opacity: 1;
    pointer-events: initial;

    .notification {
      &__container {
        transform: translateY(0);
      }
    }

    .overlay {
      opacity: 1;
      pointer-events: initial;
      cursor: pointer;
    }
  }
}