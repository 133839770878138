.courses-block {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  max-width: 500px;
  margin: 0 auto;

  @media(min-width: $tablet) {
    max-width: 100%;
  }
}