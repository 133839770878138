@use '../../../scss/variables' as *;

.contract {
  position: relative;

  &__title {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 140% !important;
    color: $dark-gray;
    display: flex;
    margin: 0 auto;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    cursor: pointer;
  }

  &__content {
    height: 0;
    overflow: hidden;
    transition: height .8s;
  }

  &__text {
    margin-top: 20px;
    font-size: 14px;
    line-height: 100%;
    color: $dark-gray;

    @media(min-width: $tablet) {
      font-size: 16px;
    }
  }

  &__btns {
    margin-top: 20px;
  }
}