@use '../../../scss/variables' as *;

.event-modal-item {
  display: flex;
  gap: 10px;
  padding: 10px 8px;
  border: 1.5px solid $gray-border-2;
  border-radius: 6px;

  @media(min-width: $laptop) {
    padding: 8px;
  }

  &__img {
    width: 18px;
    height: 16px;
    flex: 0 0 18px;
    object-fit: contain;
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    color: $dark-gray;
    margin-bottom: 4px;

    @media(min-width: $laptop) {
      font-weight: 400;
    }
  }

  &__desc {
    font-size: 10px;
    line-height: 1;
    color: rgba($dark-1, .6);
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btns {
    display: flex;
    gap: 12px;
    margin: auto 0 auto auto;

    @media(min-width: $laptop) {
      gap: 8px;
    }
  }

  &__btn {
    @media(min-width: $laptop) {
      box-shadow: none;
      width: 18px;
      height: 18px;
      flex: 0 0 18px;
    }

    .icon {
      @media(min-width: $laptop) {
        width: 18px;
        height: 18px;
        flex: 0 0 18px;
      }
    }
  }
}