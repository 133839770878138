@use '../../scss/variables' as *;

.socials {
  display: flex;
  align-items: center;
  gap: 15px;

  li {
      display: flex;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    flex: 0 0 34px;
    background-color: $white;
    border-radius: 50%;
    transition: .4s;

    &:hover {
      background-color: $bg;
    }

    svg,
    img {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}