.reviews {
  margin: 15px auto 0;

  @media(min-width: $laptop) {
    margin: 30px auto 0;
  }  

  &__head {
    @media(min-width: $laptop) {
      display: none;
    }
  }

  &__content {
    margin: 15px auto 0;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media(min-width: $laptop) {
      margin: 30px auto 0;
      gap: 30px;
    }
  }

  &-form {
    &__btn {
      margin-top: 24px;
    }
  }
}