@use '../../../../scss/variables' as *;

.program-item {
  position: relative;
  padding-top: 15px;

  &::after {
    content: '';
    position: absolute;
    left: 37px;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: $gray-border;
  }

  &:first-child {
    padding-top: 0;

    &::after {
      content: none;
    }
  }

  &-content {
    display: flex;
    gap: 12px;
    padding: 20px 15px;
    border-radius: 5px;
    border: 1px solid $gray-1;
    background: $white;
    box-shadow: 0px 0px 10px 0px rgba($black, 0.02);
    position: relative;
    z-index: 2;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__details {
    flex-grow: 1;
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: $green;
    border: 4px solid $gray-count;
    border-radius: 50%;
    min-width: 40px;
    height: 40px;
    padding: 2px;

    @media(min-width: $tablet) {
      min-width: 44px;
      min-height: 44px;
      font-size: 20px;
    }

    &.br-green {
      border-color: $green;
    }

    &.br-yellow {
      border-color: $yellow-dark;
    }

    &.br-red {
      border-color: $red-secondary;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1;
    color: $dark-gray;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1;
    margin-top: 5px;
    color: rgba(#1E1D1D, .4);
  }

  &__arrow {
    transform: rotate(180deg);
  }

  &__icon {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;

    @media(min-width: $tablet) {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
    }
  }
}