@use '../../scss/variables' as *;

.progress {
    display: flex;
    align-items: center;
    gap: 8px;

    &__count {
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: $green;
    }

    &-block {
        position: relative;
        background-color: #E7E7E7;
        border-radius: 13px;
        height: 14px;
        width: 100%;
        overflow: hidden;

        &__line {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            border-radius: 13px;
            background-color: $green;
        }
    }
}