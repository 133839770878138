@use '../../scss/variables' as *;

.head-tooltip {
  .tooltip {
    &__text {
      top: calc(100% + 10px);
      bottom: initial;
      transform: translateX(0);
      right: calc(100%  - 28px);
      left: initial;
      border-radius: 6px;
      padding: 20px 18px;
      box-shadow: 0 0 15px 0 rgba($black, .1);
      z-index: 5;

      &::before,
      &::after {
        top: initial;
        bottom: 100%;
        transform: translateX(0);
        left: calc(100% - 24px);
        border-width: 8px;
        border-color: transparent transparent #fff transparent;
      }
    }
  }
}