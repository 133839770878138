.schedule {
  display: flex;
  gap: 6px;
  max-width: 1280px;
  padding: 0 15px;
  margin: 22px auto 0;
  position: relative;

  @media(min-width: $tablet) {
    flex-direction: row-reverse;
  }

  @media(min-width: $laptop) {
    margin: 24px auto 0;

  }

  &__events {
    @media(min-width: $laptop) {
      margin-top: 80px;
      flex: 0 1 310px;
    }
  }

  &__tooltip {
    position: absolute;
    top: 4px;
    right: 15px;
  }
}