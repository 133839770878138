.finance-message {
    height: 100%;

    &__content {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        height: calc(100% - 150px);

        @media(min-width: $tablet) {
            margin-top: 80px;
        }
    }

    &__text {
        margin: 0 auto;
        max-width: 850px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 100%;
        color: $dark;

        @media(min-width: $tablet) {
            font-size: 30px;
        }
    }

    &__btns {
        width: 260px;
        margin: 0 auto;

        button {
            text-transform: uppercase;
        }
    }
}