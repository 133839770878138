@use '../../scss/variables' as *;

.homework-hint {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__block {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__text {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.1;
    text-align: left;
    color: $dark-gray;

    &:before {
      content: '';
      width: 7px;
      height: 26px;
      border-radius: 9px 0 0 9px;
    }

    &--gray {
      &::before {
        background-color: $gray-line;
      }
    }

    &--green {
      &::before {
        background-color: $green;
      }
    }

    &--yellow {
      &::before {
        background-color: $yellow-line;
      }
    }

    &--red {
      &::before {
        background-color: $red-secondary;
      }
    }
  }
}