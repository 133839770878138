@use '../../scss/variables' as *;

.notification-header {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 30;
  pointer-events: none;
  transition: .4s;

  @media(min-width: $tablet) {
    position: absolute;
    top: 40px;
    width: 375px;
    height: 595px;
    right: 0;
    left: 50%;
    bottom: initial;
    transform: translateX(-50%);
  }

  .overlay {
    @media(min-width: $tablet) {
      display: none;
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 0 0 10px 10px;
    height: 70%;
    transform: translateY(-100%);
    z-index: 35;
    transition: .3s;

    @media(min-width: $tablet) {
      width: 100%;
      height: 100%;
      border-radius: 10px;
       transform: translateY(0);
      box-shadow: 0 0 15px 0 rgba($black, .1);
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 36px 12px 0;

    @media(min-width: $tablet) {
      padding: 24px 12px 0;
    }

    .icon {
      @media(min-width: $tablet) {
        display: none;
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    color: $violet;
    height: 28px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    padding: 16px 12px 24px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__plug {
    margin: 20% auto auto auto;
    font-weight: 600;
    font-size: 28px;
    line-height: 1;
    color: $dark-gray;
  }

  &.open {
    opacity: 1;
    pointer-events: initial;

    .notification-header {
      &__container {
        transform: translateY(0);
      }
    }

    .overlay {
      opacity: 1;
      pointer-events: initial;
    }
  }
}