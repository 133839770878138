@use '../../../../scss/variables' as *;

.programs-link {
  display: flex;
  align-items: center;
  gap: 5px;
  
  .icon {
    color: $violet;
  }

  .link {
    font-weight: 400;
    font-size: 16px;
    color: $violet;
    margin: 0;

    &::after {
      background-color: $violet;
    }
  }
}