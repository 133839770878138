@use '../../scss/variables' as *;

.btn-icon {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;

	&--md {
		width: 20px;
		height: 20px;
	}

	&--lg {
		width: 32px;
		height: 32px;
	}

	&--white {
		background-color: $white;
		box-shadow: 0 0 15px 0 rgba($black, .1);
		border-radius: 6px;
	}

	@media(hover: hover) {
		&:hover {
			.icon {
				color: $violet;

				&--violet {
					color: $green;	
				}
			}
		}
	}

	&:disabled {
		cursor: not-allowed;
    color: $gray-line;
    
		@media(hover: hover) {
			&:hover {
				.icon {
					color: $gray-line;
				}
			}
		}
  }
}