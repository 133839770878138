@use '../../scss/variables' as *;

.course-block {
  position: relative;
  margin-top: 21px;
  width: 100%;
  display: flex;
  background-color: rgba($violet, .08);
  border-radius: 9px;
  height: 80px;
  cursor: pointer;

  @media(min-width: $tablet) {
    width: calc((100% - 25px) / 2);
  }

  @media(min-width: $laptop) {
    width: calc((100% - 50px) / 3);
  }

  span {
    position: absolute;
    left: 0;
    top: -21px;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.32px;
    color: $white;
    padding: 5px 10px;
    border-radius: 9px 9px 9px 0px;
    background-color: $violet;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  &__label {
    position: absolute;
    right: 0;
    top: -15px;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    background-color: $green;
    color: $white;
    border-radius: 34px;
    padding: 8px 10px;
    cursor: pointer;
  }
}