@use '../../scss/variables' as *;

.card-user {
	background-color: $white;
	border: 1px solid $gray-1;
	box-shadow: 0px 0px 10px rgba($black, 0.02);
	border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media(min-width: $tablet) {
    padding: 20px 28px;
  }

  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &-content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: $dark-gray;

    @media(min-width: $tablet) {
      font-size: 18px;
    }
  }

  &__text {
    margin-top: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: rgba($black, .4);

    @media(min-width: $tablet) {
      margin-top: 10px;
      font-size: 18px;
    }
  }

  &__link {
    font-weight: 500 !important;

    @media(min-width: $tablet) {
      font-size: 16px !important;
    }

    &:disabled {
      color: $dark-gray-1;
      cursor: not-allowed;

      &::after {
        width: 0;
      }

      &:hover {
        color: $gray-60;
      }
    }
  }
}