@use '../../scss/variables' as *;

.tag {
  padding: 10px 15px;
  background-color: $white;
  border: 1px solid rgba($dark-gray, .5);
  border-radius: 30px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .4s;

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  span {
    font-size: 14px;
    line-height: 100%;
    color: $dark-gray;
    display: flex;
    transition: .4s;
  }

  &.active {
    background-color: $green;
    border: 1px solid $green;

    span {
      color: $white;
    }
  }
}