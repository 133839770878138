@use '../../../scss/variables' as *;

.card-settings {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid $gray-1;
  background-color: $white;
  box-shadow: 0px 0px 10px 0px rgba($black, 0.02);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;

    &--col {
      align-items: start;
      flex-direction: column;

      @media(min-width: $laptop) {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &__message {
    border: 1px solid $gray-1;
    border-radius: 5px;
    background-color: $gray-1;
    padding: 5px 10px;
    color: $red;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
  }

  &__title {
    color: $dark-gray;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
  }

  &__select {
    width: 100%;

    @media(min-width: $laptop) {
      width: 60%;
    }
  }

  &+.error-message {
    margin-top: 5px !important;
  }
}