.counter {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: -6px;
    top: -2px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    border: 3px solid $gray-counter;
    background-color: $red-secondary;
    pointer-events: none;
  }
}