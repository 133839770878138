@use '../../scss/variables' as *;

.test-answer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 16px;
  background-color: $white;
  border-radius: 6px;
  border: 2px solid transparent;
  box-shadow: 0 0 15px 0 rgba($black, .1);
  cursor: pointer;
  transition: .4s;

  @media(min-width: $tablet) {
    padding: 18px 14px;
  }

  @media(hover: hover) {
    &:hover {
      border-color: $violet;
    }
  }

  &--lg {
    padding: 22px 8px;

    @media(min-width: $tablet) {
      padding: 22px 12px;
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;  
    width: 24px;
    height: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    padding-top: 2px;
    border: 2px solid $green;
    color: $green;
    border-radius: 50%;
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.1;
    color: $dark-gray;
  }

  &.choose {
    border-color: $violet;
  } 

  &.disabled {
    cursor: not-allowed;

    @media (hover: hover) {
      &:hover {
        border-color: transparent;
      }
    }

    &:not(.correct),
    &:not(.incorrect) {
      pointer-events: none;
    }
  }

  &.correct {
    border-color: $green;
    animation: blinkShadow .2s ease-in-out 8;
  }

  &.incorrect {
    border-color: $red-line;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes blinkShadow {
  0% { border-color: $green; box-shadow: 0 0 0 transparent; }
  50% { border-color: $green; box-shadow: 0 0 30px rgba($green, 1); }
  100% { border-color: $green; box-shadow: 0 0 0 transparent; }
}